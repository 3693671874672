import queryString from 'query-string';
import Storage from './storage.class.js';

class DeploymentApi {

  constructor() {

    // init
    // this.baseUrl = process.env.REACT_APP_DEPLOY_API_BASE_URL || 'http://localhost:5001'
    // this.baseUrl = process.env.REACT_APP_DEPLOY_API_BASE_URL || 'https://staging.device-registration.edge.x-guard.nl';
    this.baseUrl = process.env.REACT_APP_DEPLOY_API_BASE_URL || 'https://device-registration.edge.x-guard.nl';

  }

  async updateGroupByResourceId(resourceId, mode, groups){

    // date
    const editDate = new Date();

     // get content
    let deviceObject = (await this.get('deployment/device', {
      ids: resourceId,
    })).returnData.result[0];

    // check if there is no device object
    if(typeof deviceObject === 'undefined'){

      // create device
      deviceObject = (await this.post('deployment/device', {
        id: resourceId,
      })).returnData;

    }

    // calculate new groups
    let newGroups = false;
    if(mode === 'replace'){

      // replace
      newGroups = groups;

    }else
    if(mode === 'prepend'){

      // add in front
      newGroups = [...groups, ...deviceObject.group];

    }else
    if(mode === 'append'){

      // add in back
      newGroups = [...deviceObject.group, ...groups];

    }

    // stop if the mode is somehow not one of the above
    if(newGroups === false){

      return false;

    }

    // remove duplicates
    newGroups = [...new Set(newGroups)];

    // save in patch
    await this.patch(`deployment/device/${resourceId}`, {
      group: newGroups,
      lastConfigUpdate: editDate,
    });

    return true;

  }

  getBaseUrl(){

    return this.baseUrl;
    
  }

  get(path = "/", data = {}){

  	// proxy
  	return this.request("GET", path, data);

  }

  post(path = "/", data = {}){

    // proxy
    return this.request("POST", path, data);

  }

  patch(path = "/", data = {}){

    // proxy
    return this.request("PATCH", path, data);

  }

  delete(path = "/", data = {}){

    // proxy
    return this.request("DELETE", path, data);

  }

  async request(method = "POST", path = "/", data = {}, body = null) {

    // check
    const append = {};

    // default content type
    let contentType = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    // check if we have a body supplied through an argument
    if(body !== null){

      append.body = body;

      // overwrite
      contentType = {
         Accept: 'application/json',
      }

    }else
    // for post, patch and delete we stringify JSON
    if(method === 'POST' || method === 'PATCH' || method === 'DELETE'){

      append.body = JSON.stringify(data)

    }

    // check auth
    const authToken = Storage.get('AuthToken');
    let authHeader = {};
    if(authToken !== null){

      // set auth token
      authHeader = {
        'X-Auth-Token': authToken
      }

    }

    // check get qs
    let qs = '';
    if(method === 'GET'){

      qs = `?${queryString.stringify(data)}`;

    }

    // options
    const options = {
      method: method,
      headers: {
        ...contentType,
        ...authHeader,
      },
      ...append
    };

    // fetch
    let result;
    try {

      // go time
      result = await fetch(`${this.baseUrl}/${path}${qs}`, options);

    } catch (e) {

      // return
      return {
        success: false,
        result: result,
        error: e
      }

    }

    // parse
    let returnData;
    try {

      returnData = await result.text();
      returnData = JSON.parse(returnData);

    } catch (e) {

      // return
      return {
        success: false,
        returnData: returnData,
        result: result,
        error: e
      }

    }

    // done
    return {
      success: true,
      returnData: returnData,
      result: result,
    }

  }

}

export default (new DeploymentApi());